import React, { useMemo, useCallback, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { CALENDAR_FORMAT } from "common/calendar";
import pathnames from "routes/pathnames";
import serveRequestErrors from "common/serve-request-errors";
import { setTravelDetail, setQuotationList, setPremiumList } from "store/slices/travel";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppSelectInputInfo from "components/app-select-input-vinfo";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import AppCalendarInput from "components/app-calendar-input";
import getCoverTypeListing from "services/get-covertype-listing";
import getCountryListing from "services/get-country-listing";
import getTripTypeListing from "services/get-tripType-listing";
import getTravelDirectionListing from "services/get-travelDirection-listing";
import AppCheckbox from "components/app-checkbox";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PageTripType = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const travel = useSelector((state) => state.travel);
	const [messageType, setMessageType] = useState("");
	const [errorCode] = useState("");
	const [buttonStates, setButtonStates] = useState([  
		{ id: 0, isChecked: false },  
		{ id: 1, isChecked: false },  
	]);  

	const initialValues = useMemo(() => {
		const info = travel?.travelDetails;

		let payload = {
			travelDirection: COMMON.TRAVEL_DIRECTION.INBOUND,
			tripType: "",
			arrival: "",
			depart: "",
			return: "",		
			destination: "Malaysia",
			coverageType: "",
			persons: "",
		};

		if (info) {
			if(info.travelDirection) payload.travelDirection = info.travelDirection;

			if (info.tripType) payload.tripType = info.tripType;

			if (info.arrival) payload.arrival = info.arrival;

			if (info.depart) payload.depart = info.depart;
			
			if (info.return) payload.return = info.return;

			if (info.destination) payload.destination = info.destination;
			
			if (info.coverageType) payload.coverageType = info.coverageType;

			if (info.persons) payload.persons = info.persons;
			
		}

		return payload;
	}, [travel]);


	const validationSchema = yup.object({
		travelDirection: yup.string().required(ERRORS.REQUIRED),
		tripType: yup.string().when('travelDirection', {
			is: (travelDirection) => travelDirection === COMMON.TRAVEL_DIRECTION.OUTBOUND,
			then: () => yup.string().required(ERRORS.REQUIRED),
			otherwise: () => yup.string().nullable(),
		}),
		arrival: yup.string().when('travelDirection', {
			is: COMMON.TRAVEL_DIRECTION.INBOUND,
			then: () => yup.string().required(ERRORS.REQUIRED),
			otherwise: () => yup.string().nullable(),
		}),
		depart: yup.string().when('travelDirection', {
			is: COMMON.TRAVEL_DIRECTION.OUTBOUND,
			then: () => yup.string().required(ERRORS.REQUIRED),
			otherwise: () => yup.string().nullable(),
		}),
		return: yup.string().when(['tripType', 'travelDirection'], {
			is: (tripType, travelDirection) => 
				tripType !== COMMON.TRIP_TYPE.ONE_WAY,
			then: () => yup.string().required(ERRORS.REQUIRED),
			otherwise: () => yup.string().nullable(),
		}),
		destination: yup.string().when('travelDirection', {
			is: COMMON.TRAVEL_DIRECTION.OUTBOUND,
			then: () => yup.string().required(ERRORS.REQUIRED),
			otherwise: () => yup.string().nullable(),
		}),
		coverageType: yup.string().required(ERRORS.REQUIRED),
		persons: yup.string().when('coverageType', {
			is: (coverageType) => 
				coverageType === COMMON.COVER_TYPE.FAMILY || 
				coverageType === COMMON.COVER_TYPE.INDIVIDUAL_V_SPOUSE,
			then: () => yup.string().required(ERRORS.REQUIRED),
			otherwise: () => yup.string().nullable(),
		}),
	});

	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleCheckBox = (id) => {
		setButtonStates((prevState) =>  
			prevState.map((button) =>  
				button.id === id  
					? { ...button, isChecked: !button.isChecked }  
						: button  
			)  
		);  
	}

	const getButtonStateById = (id) => {  
		return buttonStates.find((button) => button.id === id).isChecked;  
	};  

	const checkIfAllButtonsChecked = useMemo(() => {  
		return buttonStates.every((button) => button.isChecked);  
	}, [buttonStates]);  

	const DeclarationData = useMemo(() => ({
		info: [
			{ 
				label: "Declaration 1", 
				value: (
					<span>
						I have read <a className="enquiry__tnc-pdf" href="https://www.easycover.my/terms/" target="_blank" rel="noreferrer">Terms and Conditions</a> together with <a className="enquiry__tnc-pdf" href="https://www.easycover.my/FAQ/" target="_blank" rel="noreferrer">FAQ</a> herein and fully understand and agree with the said Terms and Conditions and FAQ.<span className="enquiry__tnc-required">*</span>
					</span>
				)},
			{ 
				label: "Declaration 2" , 
				value: (
					<span>
						I consent to the<a className="enquiry__tnc-pdf" href="https://www.easycover.my/privacy-policy-2/" target="_blank" rel="noreferrer"> Data Protection and Privacy Policy</a>.<span className="enquiry__tnc-required">*</span>
					</span>
				)},
		],
		
	}), []);

	const onHandleNavigate = useCallback(() => {
		navigate(pathnames.quotation);
	}, [navigate]);

	const onHandleSubmit = async (values) => {
		let response = null;
		if(checkIfAllButtonsChecked){
		try {
			let payload = {
				travelDirection: values.travelDirection,
				tripType: values.tripType,
				arrival: values.arrival,
				depart: new Date(values.depart),
				return: values.tripType !== COMMON.TRIP_TYPE.ONE_WAY ? values.return : null,
				destination: values.destination,
				coverageType: values.coverageType,
				persons: values.persons,
				referralCode: travel?.referralCode || null,
			};
			dispatch(setTravelDetail(payload));

			[response] = await Promise.all([
				api.post.travel.postQuot(payload),
				//api.post.travel.postPremium(payload),
			]);
		}
		catch (error) {
			if (!error?.response?.data?.errorCode) {
				if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
					setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
				} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
					setMessageType(COMMON.MESSAGE_TYPE.INVALID);
				}
			} else {
				serveRequestErrors(error);
			}
		} 
		finally {
			formik.setSubmitting(false);
		}

		
		if (response) {
			//console.log('response: ', response);

			dispatch(setQuotationList(response));
			onHandleNavigate();
		}
		}
		else
		{
			formik.setSubmitting(false);
		}

	};

	const getPersonsOptions = useMemo(() => {
		switch (formik.values.coverageType) {
			case COMMON.COVER_TYPE.INDIVIDUAL:
				return [{
					value: '1',
					label: '1'
				}];
			case COMMON.COVER_TYPE.INDIVIDUAL_V_SPOUSE:
				return [{
					value: '2',
					label: '2',
				}];
			case COMMON.COVER_TYPE.FAMILY:
				return Array.from({ length: 5 }, (_, i) => ({
					value: (i + 2).toString(),
					label: (i + 2).toString(),
				}));
			default:
				return [];
		}
	}, [formik.values.coverageType]);

	const handleCoverageTypeChange = useCallback((e) => {
		const coverageType = e.target.value;
		formik.setFieldValue('coverageType', coverageType);
		
		switch (coverageType) {
			case COMMON.COVER_TYPE.INDIVIDUAL:
				formik.setFieldValue('persons', '1');
				break;
			case COMMON.COVER_TYPE.INDIVIDUAL_V_SPOUSE:
				formik.setFieldValue('persons', '2');
				break;
			case COMMON.COVER_TYPE.FAMILY:
				formik.setFieldValue('persons', '2');
				break;
			default:
				formik.setFieldValue('persons', 'N/A');
		}
	}, [formik]);

	

	const handleTripTypeChange = useCallback((e) => {
		const tripType = e.target.value;
		formik.setFieldValue('tripType', tripType);
		
		// Reset return date if trip type is ONE_WAY
		if (tripType === COMMON.TRIP_TYPE.ONE_WAY) {
			formik.setFieldValue('return', '');
		}
	}, [formik]);



	const onHandleTravelDirection = useCallback((e) => {
		const travelDir = e.target.value;

		// Example for multiple fields
		const resetFields = () => {
		    formik.setFieldValue('tripType', '');
		    formik.setFieldValue('coverageType', '');
		    formik.setFieldValue('arrival', '');
		    formik.setFieldValue('depart', '');
		    formik.setFieldValue('return', '');
		};

		formik.setFieldValue('travelDirection', travelDir);
	
		if (travelDir === COMMON.TRAVEL_DIRECTION.INBOUND) {
			resetFields();
			formik.setFieldValue('destination', 'Malaysia');
			
		} else {
			// Reset destination or handle other logic if needed
			resetFields();
			formik.setFieldValue('destination', '');
		}
	}, [formik]);


	const onHandleDateChange = useCallback((name, value) => {
		// Set time to midnight (00:00:00)
		const normalizeDate = (value) => {
			if (!value) return null;
			const newDate = new Date(value);
			newDate.setHours(0, 0, 0, 0);
			return newDate;
		};
	
		// Normalize the incoming date
		const normalizedDate = normalizeDate(value);
		formik.setFieldValue(name, normalizedDate);
	
		// Case 1: If arrival date is selected and there's a return date
		if (name === 'arrival' && formik.values.return) {
			const arrivalDate = normalizeDate(normalizedDate);
			const returnDate = normalizeDate(formik.values.return);
			
			if (arrivalDate > returnDate) {
				formik.setFieldValue('return', '');
			}
		}
	
		// Case 2: If departure date is selected and there's a return date
		if (name === 'depart' && formik.values.return) {
			const departDate = normalizeDate(normalizedDate);
			const returnDate = normalizeDate(formik.values.return);
			
			if (departDate > returnDate) {
				formik.setFieldValue('return', '');
			}
		}
	
		// Case 3: If return date is selected, validate against arrival/depart
		if (name === 'return') {
			const returnDate = normalizeDate(normalizedDate);
			
			if (formik.values.arrival) {
				const arrivalDate = normalizeDate(formik.values.arrival);
				if (returnDate < arrivalDate) {
					formik.setFieldValue('arrival', '');
					return;
				}
			}
	
			if (formik.values.depart) {
				const departDate = normalizeDate(formik.values.depart);
				if (returnDate < departDate) {
					formik.setFieldValue('depart', '');
					return;
				}
			}
		}
	}, [formik]);

	return (
		<div className="page-trip-type">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							{!messageType && !formik.isSubmitting && (
								<div className="enquiry__wrapper">
									<h1 className="enquiry__title">Travel Insurance Enquiry</h1>
									<p className="enquiry__description">Send us an enquiry and we will come back to you!</p>

									<div className="enquiry__box-body">
										{/* prettier-ignore */}
										<AppSelectInputInfo 
											required 
											type="text" 
											name="travelDirection" 
											label="Travel Direction" 
											placeholder="Please Select" 
											loadOptions={getTravelDirectionListing} 
											value={formik.values.travelDirection} 
											error={formik.errors.travelDirection} 
											touched={formik.touched.travelDirection} 
											onChange={onHandleTravelDirection} 
											disabled={formik.isSubmitting} 
											searchable={false} 
										/>

										{/* prettier-ignore */}
										{ formik.values.travelDirection !== COMMON.TRAVEL_DIRECTION.INBOUND && (
											<AppSelectInputInfo 
												required 
												type="text" 
												name="tripType" 
												label="Travel Type"
												placeholder="Please Select" 
												loadOptions={getTripTypeListing} 
												value={formik.values.tripType} 
												error={formik.errors.tripType} 
												touched={formik.touched.tripType} 
												onChange={handleTripTypeChange} 
												disabled={formik.isSubmitting} 
												searchable={false} 
											/>
										)}

										{/* prettier-ignore */}
										{ formik.values.travelDirection === COMMON.TRAVEL_DIRECTION.INBOUND && (
											<AppCalendarInput 
												required 
												disabled={formik.isSubmitting} 
												name="arrival" 
												label="Arrival Date" 
												placeholder="e.g. 16 July 1994" 
												minDate={new Date()} 
												displayFormat={calendarDisplayFormat} 
												value={formik.values.arrival} 
												error={formik.errors?.arrival} 
												touched={formik.touched?.arrival} 
												onChange={onHandleDateChange}
											/>
										)}

										{/* prettier-ignore */}
										{ formik.values.travelDirection === COMMON.TRAVEL_DIRECTION.OUTBOUND && (
											<AppCalendarInput 
												required 
												disabled={formik.isSubmitting} 
												name="depart" 
												label="Departure Date" 
												placeholder="e.g. 16 July 1994" 
												minDate={new Date()} 
												displayFormat={calendarDisplayFormat} 
												value={formik.values.depart} 
												error={formik.errors?.depart} 
												touched={formik.touched?.depart} 
												onChange={onHandleDateChange}
											/>
										)}
										
										{/* prettier-ignore */}
										{formik.values.tripType !== COMMON.TRIP_TYPE.ONE_WAY  && (
											<AppCalendarInput 
												required 
												disabled={formik.isSubmitting} 
												name="return" 
												label="Return Date" 
												placeholder="e.g. 16 July 1994" 
												minDate={new Date()} 
												displayFormat={calendarDisplayFormat} 
												value={formik.values.return} 
												error={formik.errors?.return} 
												touched={formik.touched?.return} 
												onChange={onHandleDateChange}
											/>
										)}

										{/* prettier-ignore */}
										<AppSelectInput 
											required 
											type="text" 
											name="destination" 
											label="Destination" 
											placeholder="Please Select" 
											items={getCountryListing} 
											loadOptions={getCountryListing} 
											value={formik.values.destination} 
											error={formik.errors.destination} 
											touched={formik.touched.destination} 
											onChange={formik.handleChange} 
											disabled={formik.isSubmitting || formik.values.travelDirection === COMMON.TRAVEL_DIRECTION.INBOUND} 
											searchable={false} 
										/>

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="coverageType" label="Coverage Type" placeholder="Please Select" loadOptions={getCoverTypeListing} value={formik.values.coverageType} error={formik.errors.coverageType} touched={formik.touched.coverageType} onChange={handleCoverageTypeChange} disabled={formik.isSubmitting} searchable={false} />
									
										{/* prettier-ignore */}
										{formik.values.coverageType !== COMMON.COVER_TYPE.INDIVIDUAL && formik.values.coverageType !== COMMON.COVER_TYPE.GROUP && formik.values.coverageType !== "" && (
											<AppSelectInput 
												required 
												type="text" 
												name="persons" 
												label="Number of Persons" 
												placeholder="Please Select" 
												options={getPersonsOptions} 
												value={formik.values.persons} 
												error={formik.errors.persons} 
												touched={formik.touched.persons} 
												onChange={formik.handleChange}
												disabled={(formik.values.coverageType !== COMMON.COVER_TYPE.FAMILY) || formik.isSubmitting}  
												searchable={false} 
											/>
										)}
									
									</div>

									{DeclarationData.info.map((o, index) => {
											return (
											<div className="enquiry__button-container--declaration">
												<AppCheckbox 
													onClick={() => onHandleCheckBox(index)}
													label={o.value}
													value={getButtonStateById(index)}
												/>
											</div>
										)})}

									<div className="enquiry__button-container">
										<AppButton type="submit" label="Next" disabled={formik.isSubmitting && !checkIfAllButtonsChecked} />
									</div>
								</div>
							)}

							<AppLoading isLoading={formik.isSubmitting} text="Processing page during quotation selection page - We're currently fetching your enquiry." />

							<AppCardStatus messageType={messageType} error={errorCode} setMessageType={setMessageType} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageTripType;
